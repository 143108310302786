<template>
  <div>
    <component class="embed-width" v-bind:is="currentComponent" v-if="currentComponent !== null"
               @resizeEmbedComponent="sendDocumentHeightToParent"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from 'lodash';

export default {

  data() {
    return {
      requestedComponent: null,
      currentComponent: null,
      authenticated: false,
      componentMappings: {
        //system required
        errorLoadingComponent: () => import('./Invalid'),

        //pages/components
        commissionsReport: () => import('@/pages/commissions/commission-report/Index'),
      }
    }
  },
  methods: {
    validateRequestedComponent() {
      return this.$_.has(this.componentMappings, this.requestedComponent);
    },
    mountComponent() {
      let validComponentAndAuth = (this.isUserLoggedIn === true && this.validateRequestedComponent() === true);

      let componentToMount = validComponentAndAuth ? this.requestedComponent : 'errorLoadingComponent';

      this.currentComponent = this.componentMappings[componentToMount];

      this.sendDocumentHeightToParent();
    },
  },
  mounted() {
    this.requestedComponent = this.$route.params.component;
    this.mountComponent();

    this.sendDocumentHeightToParent()
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'isUserLoggedIn',
    }),
  },
}
</script>

<style scoped>
.embed-width {
  width: 100%;
  max-width:1200px;
  margin-left:auto;
  margin-right:auto;
}
</style>

<style>
#app {
  background: none !important;
}
</style>
